<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="cardName"
                    label="卡名称"
                >
                    <el-input
                        v-model="queryFormModel.cardName"
                        placeholder="请输入卡名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="状态"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="启用"
                            value="1"
                        />
                        <el-option
                            label="禁用"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="cardName"
                    label="卡名称"
                    min-width="100"
                />
                <el-table-column
                    prop="cardValue"
                    label="卡价值"
                    min-width="100"
                />
                <el-table-column
                    prop="cardValue"
                    label="卡价值"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.cardLogoUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="cardName"
                    label="卡名称"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.cardName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="cardValue"
                    label="卡价值"
                    label-width="7em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.cardValue"
                    />
                </el-form-item>
                <el-form-item
                    prop="cardLogo"
                    label="充值卡图片"
                    label-width="7em"
                >
                    <upload
                        action="/rs/attachment/uploadPrepaidCardImage"
                        :limit="1"
                        v-model="addDialog.formModel.cardLogo"
                    />
                </el-form-item>
                <el-form-item
                    prop="cardDesc"
                    label="卡描述"
                    label-width="7em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.cardDesc"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="7em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
                <el-form-item
                    label="会员等级配置"
                    label-width="7em"
                >
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="addDialog.prepaidCardPriceList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="levelName"
                            label="等级名称"
                            min-width="100"
                        />
                        <el-table-column
                            label="等级图片"
                            width="120"
                        >
                            <template slot-scope="scope">
                                <ImageList
                                    :data="scope.row.levelIconUrl"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="cardPrice"
                            label="会员价格"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0.01"
                                    :precision="2"
                                    v-model.number="scope.row.cardPrice"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <!--<el-form-item>
                    <span
                        class="m-t">
                        <span v-show="!addDialog.isSetBatchPrice">
                            <el-button
                                type="text"
                                size="small"
                                @click="onBatchSetPrice"
                            >批量设置
                            </el-button>
                        </span>
                        <span v-show="addDialog.isSetBatchPrice">
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="addDialog.batchPrice"/>
                            <el-button
                                type="text"
                                size="small"
                                @click="onConfirmBatchSetPrice()"
                            >保存
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="onCancelBatchSetPrice()"
                            >取消
                            </el-button>
                        </span>
                    </span>
                </el-form-item>-->
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'PrepaidCard',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                cardName: '', // 卡名称
                isAvailable: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    cardName: '',
                    cardValue: '',
                    cardLogo: [],
                    cardDesc: '',
                    isAvailable: true,
                },
                // 表单校验规则
                formRules: {
                    cardName: {
                        required: true,
                        message: '请输入卡名称',
                        trigger: 'blur',
                    },
                    cardValue: {
                        required: true,
                        message: '请输入卡价值',
                        trigger: 'blur',
                    },
                    cardLogo: {
                        type: 'array',
                        required: true,
                        message: '请上传充值卡图片',
                    },
                    isAvailable: {
                        required: true,
                        message: '请选择是否可用',
                    },
                },
                prepaidCardPriceList: [],
                batchPrice: 0,
                isSetBatchPrice: false,
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            buyerLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.PrepaidCard.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
                this.addDialog.prepaidCardPriceList = [...this.buyerLevelList];
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Mb.PrepaidCard.getDetail({ id: row.id }).then(json => {
                const res = json.data.data.prepaidCard;
                this.$utils.Common.formModelMerge(formModel, res);
                this.addDialog.prepaidCardPriceList = json.data.data.prepaidCardPriceList;
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.PrepaidCard.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.PrepaidCard.save({
                    ...this.addDialog.formModel,
                    priceListJson: JSON.stringify(this.addDialog.prepaidCardPriceList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.PrepaidCard.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        // 批量设置价格
        onBatchSetPrice() {
            this.$set(this.addDialog, 'isSetBatchPrice', true);
            this.$set(this.addDialog, 'batchPrice', 0);
        },
        onConfirmBatchSetPrice() {
            if (this.addDialog.batchPrice !== 0) {
                this.addDialog.prepaidCardPriceList.forEach(item => {
                    item.costPrice = this.addDialog.batchPrice;
                });
            }
            this.$set(this.addDialog, 'isSetBatchPrice', false);
        },
        onCancelBatchSetPrice() {
            this.$set(this.addDialog, 'isSetBatchPrice', false);
        },
        initBuyerLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                res.forEach(item => {
                    item.buyerLevelId = item.id;
                    item.id = null;
                    item.costPrice = null;
                });
                this.buyerLevelList = res;
            });
        },
    },
    created() {
        this.initBuyerLevelList();
    },
};
</script>

<style lang="scss">
</style>
